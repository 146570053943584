
































import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import store from "@/store";

@Component({})
export default class extends Vue {
  @Prop({ required: true }) private tableData: any;
  @Prop({ default: false }) private loading?: boolean;
  @Prop({ default: { pageSize: 20, page: 0 } }) pages?: any;
  @Prop({ default: false }) private total?: number;
  @Prop({ default: false }) private border?: boolean;
  @Prop({ required: false }) private rowKey?: any;
  @Prop({ required: false }) private expandRowKeys?: any[];

  protected changePage(page: number) {
    (this.$parent as any).search.pagination.page = page - 1;
    (this.$parent as any).searchTable();
  }
  protected sort(column: any) {
    let timeStatus;
    if (column.order === "ascending") {
      timeStatus = 4;
    } else if (column.order === "descending") {
      timeStatus = 3;
    } else {
      timeStatus = "";
    }
    var search: any = {};
    if (timeStatus !== "") {
      search[column.prop] = timeStatus;
    }
    (this.$parent as any).search.sort = search;
    (this.$parent as any).searchTable(true);
  }
  protected expandChange(row: any, expandedRows: any) {
    this.$emit("expandChange", row, expandedRows);
  }
  protected selectionChange(val: any[]) {
    this.$emit("selectionChange", val);
  }

  beforeUpdate() {
    this.setSlot();
  }

  ///////////////////////表格筛选/////////////////////
  //全选
  checkAll = false;
  isIndeterminate = false;
  //是否渲染表格
  isTable = true;
  //是否显示筛选器
  cellDialog = false;
  //已选中筛选项
  checkList: any = [];
  //所有列
  columnsList: any = [];
  //是否默认
  isDefault = true;
  //当前列
  _default: any = [];
  //排序继承
  sort_default = { prop: "AAA", order: "descending" };
  //全选事件
  handleCheckAllChange(val: boolean) {
    let _options: any = [];
    this.columnsList.forEach((item: any, index: number) => {
      _options.push(index);
    });
    this.checkList = val ? _options : [];
    this.isIndeterminate = false;
  }
  handleCheckedCitiesChange(value: any) {
    let checkedCount = value.length;
    this.checkAll = checkedCount === this.columnsList.length;
    this.isIndeterminate =
      checkedCount > 0 && checkedCount < this.columnsList.length;
  }
  //读取缓存 暂时先这样
  columnCache = store.state.system.column;
  //记录缓存
  addCache(checkids: string) {
    store.dispatch("system/addColumn", {
      name: location.hash,
      value: checkids,
    });
  }
  //设置视图
  setSlot() {
    if (!this.isDefault) {
      //设置列
      this.$slots.diy = this._default;
    }
  }

  //监听数据源改变
  @Watch("tableData")
  getTableData() {
    // 找出排序对象名
    this.sort_default = { prop: "AAA", order: "descending" };
    if ((this.$parent as any).search.sort) {
      Object.keys((this.$parent as any).search.sort).forEach((item: any) => {
        this.sort_default.prop = item;
        this.sort_default.order =
          (this.$parent as any).search.sort[item] == 4
            ? "ascending"
            : "descending";
      });
    } else if ((this.$parent as any).search.default_order) {
      Object.keys((this.$parent as any).search.default_order).forEach(
        (item: any) => {
          this.sort_default.prop = item;
          this.sort_default.order =
            (this.$parent as any).search.default_order[item] == 4
              ? "ascending"
              : "descending";
        }
      );
    }
    if (this.isDefault) {
      //重新拉取
      this.columnsList = [];
      this.columnsList = this.columnsList.concat(this.$slots.default);
    }
    console.log(this.columnsList);
    this.columnCache = Array.from(this.columnCache);
    //判定是否有缓存，有则提前设置列
    this.columnCache.forEach((item: any, index: number) => {
      if (item.name === location.hash) {
        this.checkList = [];
        item.value.split(",").forEach((val: string) => {
          if (val) {
            this.checkList.push(parseInt(val));
          }
        });
        this.setTable(item.value);
        return false;
      }
    });
  }

  SaveTable() {
    if (this.checkList.length === 0) {
      this.$message.warning("请设置要选择的列");
      return;
    }
    this.setTable()
  }

  //设置列
  setTable(checkids = "") {
    if (!checkids && this.checkList.length == 0) {
      //设置默认
      this.isDefault = true;
      //记录缓存
      this.addCache("");
      //隐藏筛选器
      this.cellDialog = false;
      //重新渲染表格
      this.isTable = false;
      setTimeout(() => {
        this.isTable = true;
      }, 10);
      return;
    }
    //转义字符串，用于判定方便
    let _checkids = checkids ? checkids : "," + this.checkList.join(",") + ",";
    //重新实例化
    this._default = [];
    //循环判定
    this.columnsList.forEach((item: any, index: number) => {
      if (_checkids.indexOf("," + index + ",") > -1) {
        // delete item.componentOptions.propsData.width;
        // delete item.componentOptions.propsData.align;
        //添加
        this._default.push(item);
      }
    });
    //设置不默认
    this.isDefault = false;
    //设置列
    this.$slots.diy = this._default;
    console.log(this._default);
    //重新渲染表格
    this.isTable = false;
    setTimeout(() => {
      this.isTable = true;
    }, 1);
    if (!checkids) {
      //记录缓存
      this.addCache(_checkids);
      //隐藏筛选器
      this.cellDialog = false;
    }
  }
}
